<template>
  <div>
    <h1 class="mt-4 mb-4">Descargas</h1>

    <p>Panel para gestionar las descargas del servidor. <a href="/transmission" target="_blank">Ir a transmission</a> </p>

    <div class="row">
      <div class="col-sm text-center">
        <router-link :to="{ name: 'Descargas' }" class="btn btn-info">Descargas</router-link>
      </div>

      <div class="col-sm text-center">
        <router-link :to="{ name: 'DescargasSeries' }" class="btn btn-info">Descargas series</router-link>
      </div>
    </div>

    <Router-view class="mt-3"></Router-view>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'

  export default {
    data() {
      return {
        notifications: [],
        loadingNotifications: true,
        errorNotifications: false
      }
    },
    methods: {
      async getNotifications() {
        try {
          this.loadingNotifications = true
          const res = await axios.get(config.apiUrl + '/descargas/notificaciones')
          this.loadingNotifications = false
          this.notifications = res.data
        } catch (e) {
          this.loadingNotifications = false
          this.errorNotifications = true
        }
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
      this.getNotifications()
    }
  }
</script>
